<template>

  <span :class="'tag ' + getComputedStyle(origin)">
    {{ getOriginName(origin) }}
  </span>

</template>

<script>

export default {
  name: 'TicketOriginTag',
  props: {
    origin: {
      type: String,
      require: true
    }
  },
  methods: {
    getOriginName (origin) {
      switch (origin) {
        case 'ATE':
          return 'Atendente'
        case 'CLI':
          return 'Cliente'
        case 'INT':
          return 'Integração'
        default:
          return 'Desconhecido'
      }
    },
    getComputedStyle (origin) {
      switch (origin) {
        case 'ATE':
          return 'ticket-attendant-tag'
        case 'CLI':
          return 'ticket-client-tag'
        case 'INT':
          return 'ticket-integration-tag'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.ticket-attendant-tag {
  background-color: rgb(198, 108, 6) !important;
  color: #FFFFFF !important;
}
.ticket-client-tag {
  background-color: rgb(8, 11, 181) !important;
  color: #FFFFFF !important;
}
.ticket-integration-tag {
  background-color: rgb(148, 69, 174) !important;
  color: #FFFFFF !important;
}
</style>
